<template>
  <StickyMenu />
</template>

<script>
import StickyMenu from '../../../views/component/StickyMenu.vue'

export default {
  components: {
    StickyMenu
  }
}
</script>
