<!-- BottomMenu.vue -->
<template>
  <div class="bottom-menu">
    <div class="menu-item left">
      <div @click="goTo('promotion')">
        <div>
          <b-img width="30" src="../../assets/images/icons/promo.png" />
        </div>
        <div class="menu-item-title">โปรโมชั่น</div>
      </div>
    </div>
    <div class="menu-item">
      <div @click="goTo('deposit')">
        <div>
          <b-img width="30" src="../../assets/images/icons/deposit.png" />
        </div>
        <div class="menu-item-title">ฝาก</div>
      </div>
    </div>
    <div class="menu-item">
      <div @click="goTo('lobby')">
        <div>
          <b-img
            class="circle"
            width="54"
            src="../../assets/images/icons/entrance.png"
          />
        </div>
      </div>
    </div>
    <div class="menu-item">
      <div @click="goTo('withdraw')">
        <div>
          <b-img width="30" src="../../assets/images/icons/withdraw.png" />
        </div>
        <div class="menu-item-title">ถอน</div>
      </div>
    </div>
    <div class="menu-item right">
      <div @click="goTo('cashback')">
        <div>
          <b-img width="30" src="../../assets/images/icons/casino.png" />
        </div>
        <div class="menu-item-title">คืนยอด</div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  data() {
    return {
      leftItems: [{ text: 'Left 1' }, { text: 'Left 2' }],
      centerItem: { text: 'Center' },
      rightItems: [{ text: 'Right 1' }, { text: 'Right 2' }]
    }
  },
  methods: {
    goTo(route) {
      console.log(route)
      this.$router.replace({ name: route })
    }
  }
}
</script>

<style scoped>
.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 99;
}
.menu-item-title {
  margin-top: 5px;
}

.menu-item {
  color: #7259cc;
  padding: 8px;
  flex: 1;
  border-top: 1.5px solid #beadfa;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  background: linear-gradient(
    to top,
    rgb(190, 173, 250, 0.8),
    #fff
  ); /* From bottom to top */
  text-shadow: 1px 1px 0 #fff, /* Top-left shadow */ -1px -1px 0 #fff,
    /* Bottom-right shadow */ 1px -1px 0 #fff,
    /* Top-right shadow */ -1px 1px 0 #fff; /* Bottom-left shadow */
}

.circle-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  border-top-left-radius: 15px;
}

.right {
  border-top-right-radius: 15px;
}

.circle {
  animation: pulse 1s infinite;
}

.center {
  color: #fff; /* Adjust as needed */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
